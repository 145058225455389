import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonPage,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  useIonAlert,
  useIonModal,
  IonCardContent,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonInput,
  IonTextarea,
  useIonLoading
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, arrowBackOutline, menuSharp, locateOutline, mapOutline, cashOutline, walletOutline ,documentOutline, cartOutline, chatboxEllipsesOutline, checkmarkCircleOutline, closeCircleOutline, bookOutline, receiptOutline, starOutline, personCircleOutline, calendarNumberOutline, cameraOutline, closeOutline} from "ionicons/icons";
import { RestApi } from '../../services/Api';
import Map from '../Map';
import HistoryVisit from '../visit/History';
import HistoryBill from "../bill/History";
import Ar from '../customer/Ar';
import Payment from '../customer/Payment';
import BackOrder from '../customer/BackOrder';
import Sales from '../customer/Sales';
import { Bill } from '../../models/Modal';
import { useTranslation } from 'react-i18next';
import { getNameDate, Rupiah } from '../../services/Library';
import SelectOption from '../input/SelectOption';
import { usePhotoGallery } from '../../services/Camera';
import { useSelector } from 'react-redux';
import { CameraSource } from '@capacitor/camera';
import { Capacitor, Plugins } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import './Detail.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<Bill> = (props) => {
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [bill, setBill] = useState<any>([]);
  const [billDet, setBillDet] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showModalSelectOptionAddress, setShowModalSelectOptionAddress] = useState<boolean>(false);
  const [address, setAddress] = useState<any>();
  const [segment, setSegment] = useState<string>("information");
  const statusPayment: any = [
    {
      name: t("no"),
      color: "danger",
    },
    {
      name: t("yes"),
      color: "success",
    },
  ];

  const handleDismissCollect = (e: any) => {
    if (e) {
      getbillDetail(props.detailBill.bill_id, props.detailBill.customer_id);
    }
    dismissCollect();
  };

  const [presentCollect, dismissCollect] = useIonModal(ModalCollect, {
    customer: customer,
    billDet: billDet,
    onDismissDetail: handleDismissCollect,
  });

  const getbillDetail = async (id: string, idCust: string) => {
    if (!id) {
      props.onDismissDetail();
      return;
    }
    setLoaded(false);
    RestApi(`bill/detail-bill/${id}/${idCust}`, "get", {}, "application/json")
      .then(async (res: any) => {
        if (res.data.code === 200) {
          setBill(res.data.data.bill);
          setCustomer(res.data.data.customer);

          setLoaded(true);
        } else {
          props.onDismissDetail();
          massageAlert("error", res.data.message);
        }
      })
      .catch((err: any) => {
        props.onDismissDetail();
        massageAlert("error", err.message);
      });
  };

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ text: "Ok", handler: (d: any) => {} }],
    });
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getbillDetail(bill.bill_id, customer?.customer_id);
    event.detail.complete();
  };

  const handleDismissMap = (e: any) => {
    getbillDetail(props.detailBill.bill_id, props.detailBill.customer_id);
    dismissMap();
  };

  const handleDismissHistoryVisit = (e: any) => {
    dismissHistoryVisit();
  };

  const handleDismissHistoryBill = (e: any) => {
    dismissHistoryBill();
  };

  const handleDismissAr = (e: any) => {
    dismissAr();
  };

  const handleDismissPayment = (e: any) => {
    dismissPayment();
  };

  const handleDismissBackOrder = (e: any) => {
    dismissBackOrder();
  };

  const handleDismissSales = (e: any) => {
    dismissSales();
  };

  const [presentMap, dismissMap] = useIonModal(Map, {
    customer: {
      customer_id: customer?.customer_id,
      customer_name: customer?.customer_name,
    },
    type: "customer",
    visit: null,
    address: address,
    onDismissDetail: handleDismissMap,
  });

  const [presentHistoryVisit, dismissHistoryVisit] = useIonModal(HistoryVisit, {
    idCustomer: customer?.customer_id,
    onDismissDetail: handleDismissHistoryVisit,
  });

  const [presentHistoryBill, dismissHistoryBill] = useIonModal(HistoryBill, {
    idCustomer: customer?.customer_id,
    onDismissDetail: handleDismissHistoryBill,
  });

  const [presentAr, dismissAr] = useIonModal(Ar, {
    idCustomer: customer?.customer_id,
    onDismissDetail: handleDismissAr,
  });

  const [presentPayment, dismissPayment] = useIonModal(Payment, {
    idCustomer: customer?.customer_id,
    onDismissDetail: handleDismissPayment,
  });

  const [presentBackOrder, dismissBackOrder] = useIonModal(BackOrder, {
    idCustomer: customer?.customer_id,
    onDismissDetail: handleDismissBackOrder,
  });

  const [presentSales, dismissSales] = useIonModal(Sales, {
    idCustomer: customer?.customer_id,
    onDismissDetail: handleDismissSales,
  });

  const addressSelectionChanged = (idAddress: string[]) => {
    setAddress(
      customer?.customer_address.find(
        (val: any) => val.customer_address_id === idAddress
      )
    );
    presentMap({
      cssClass: "modal-fullscreen",
    });
    return;
  };

  useConstructor(() => {
    getbillDetail(props.detailBill.bill_id, props.detailBill.customer_id);
  });

  useEffect(() => {}, [bill,customer]);

  return (
    <IonPage className="detail-content">
      <IonHeader>
        <IonToolbar class="toolbar-detail-bill ion-no-padding">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => props.onDismissDetail()}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonItem className="ion-no-padding">
            <IonLabel className="ion-no-padding" color="light">
              <h4>
                <strong>
                  {customer?.customer_name} ({customer?.customer_title})
                </strong>
              </h4>
              <IonBadge className="ion-no-padding" color="dark">
                {customer?.customer_id}
              </IonBadge>
            </IonLabel>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}

        {loaded ? (
          <>
            {/* customer */}
            <IonCard className="ion-no-padding">
              <IonSegment
                scrollable={true}
                value={segment}
                className="ion-no-padding ion-no-margin"
              >
                <IonSegmentButton
                  value="information"
                  onClick={() => {
                    setSegment("information");
                  }}
                >
                  <IonLabel>{t("information")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="address"
                  onClick={() => {
                    setSegment("address");
                  }}
                >
                  <IonLabel>{t("address")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="contact"
                  onClick={() => {
                    setSegment("contact");
                  }}
                >
                  <IonLabel>{t("contact")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="detail"
                  onClick={() => {
                    setSegment("detail");
                  }}
                >
                  <IonLabel>{t("detail")}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonCardContent className="ion-no-padding ion-no-margin">
                {segment === "information" && (
                  <IonText className="ion-padding-start">
                    <p className="ion-no-padding ion-no-margin">
                      Handphone :<strong>{customer.customer_phone}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Telpon :<strong>{customer.customer_phone}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Fax :<strong>{customer.customer_fax}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Email :<strong> {customer.customer_email}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Status :
                      <strong>
                        {customer.customer_is_active ? "AKTIF" : "TIDAK AKTIF"}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />

                    <p className="ion-no-padding ion-no-margin">
                      Area :
                      <strong>
                        {customer.subarea_name} -{customer.area_name}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Kota :<strong> {customer.customer_city}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Propinsi :<strong> {customer.customer_state}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Negara :<strong>{customer.customer_country}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Kode Pos :<strong>{customer.customer_zipcode}</strong>
                    </p>
                  </IonText>
                )}
                {segment === "contact" && (
                  <IonText className="ion-padding-start">
                    <p className="ion-no-padding ion-no-margin">
                      Nama Kontak :
                      <strong>{customer.customer_contact_name}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Tanggal Lahir :
                      <strong>
                        {getNameDate(customer.customer_contact_birthday)}
                      </strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Jabatan :
                      <strong>{customer.customer_contact_title}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Hobby :<strong>{customer.customer_contact_hobby}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Handphone :
                      <strong>{customer.customer_contact_mobile}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Email :<strong>{customer.customer_contact_email}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nama KTP :
                      <strong>{customer.customer_identity_card_name}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      No. KTP :
                      <strong>{customer.customer_identity_card_number}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nama NPWP :<strong>{customer.customer_tax_name}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                    <p className="ion-no-padding ion-no-margin">
                      Nomor NPWP :
                      <strong>{customer.customer_tax_number}</strong>
                    </p>
                    <hr className="ion-no-padding ion-no-margin" />
                  </IonText>
                )}
                {segment === "address" && (
                  <>
                    {customer.customer_address.map((address: any) => (
                      <IonCard
                        key={address.customer_address_id}
                        className="ion-margin"
                        style={{
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          borderRadius: "12px",
                        }}
                      >
                        <IonCardContent>
                          <IonRow className="ion-no-padding ion-no-margin ion-align-items-center">
                            <IonCol
                              size="6"
                              className="ion-no-padding ion-no-margin"
                            >
                              <IonBadge
                                color={
                                  address.customer_address_is_active === 1
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {address.customer_address_is_active === 1
                                  ? "Aktif"
                                  : "InAktif"}
                              </IonBadge>
                            </IonCol>
                            <IonCol
                              size="6"
                              className="ion-no-padding ion-no-margin ion-text-end"
                            >
                              {address.customer_address_is_default === 1 && (
                                <IonIcon
                                  icon={starOutline}
                                  color="warning"
                                  size="small"
                                />
                              )}
                            </IonCol>
                            <IonCol
                              size="12"
                              className="ion-no-padding ion-no-margin"
                            >
                              <IonText>
                                <h3
                                  style={{
                                    margin: 0,
                                    color: "#333",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {address.customer_address_name}
                                </h3>
                                <p style={{ color: "#666" }}>
                                  {address.customer_address}
                                </p>
                                <hr className="ion-no-padding ion-no-margin" />
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
                )}
                {segment === "detail" && (
                  <>
                    <IonText className="ion-padding-start">
                      <p className="ion-no-padding ion-no-margin">
                        Website URL :<strong> {customer.customer_url}</strong>
                      </p>
                      <hr className="ion-no-padding ion-no-margin" />
                      <p className="ion-no-padding ion-no-margin">
                        Keterangan :<strong>{customer.customer_remark}</strong>
                      </p>
                      <hr className="ion-no-padding ion-no-margin" />
                    </IonText>
                  </>
                )}
              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardContent className="ion-no-padding ion-no-margin">
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol size="12">
                    <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
                      <IonItem>
                        <IonText className="ion-no-padding ion-no-margin">
                          <p className="ion-no-padding ion-no-margin">
                            <IonIcon
                              size="small"
                              className="ion-no-padding ion-no-margin"
                              src={cashOutline}
                            />
                            {t("payment")} ?{" "}
                            <IonBadge
                              slot="end"
                              color={statusPayment[bill.bill_has_receipt].color}
                            >
                              {statusPayment[bill.bill_has_receipt].name}
                            </IonBadge>
                          </p>
                          <p className="ion-no-padding ion-no-margin">
                            <IonIcon
                              size="small"
                              className="ion-no-padding ion-no-margin"
                              src={personCircleOutline}
                            />
                            {bill.bill_collector_name}
                          </p>
                        </IonText>
                        <IonText
                          className="ion-no-padding ion-no-margin ion-text-end"
                          slot="end"
                        >
                          <p className="ion-no-padding ion-no-margin">
                            <strong>{bill.bill_id}</strong>
                          </p>
                          <p className="ion-no-padding ion-no-margin">
                            <IonIcon
                              size="small"
                              style={{
                                marginRight: "2px",
                              }}
                              src={calendarNumberOutline}
                            />
                            <strong>{getNameDate(bill.bill_date)}</strong>
                          </p>
                        </IonText>
                      </IonItem>
                      <IonCardContent className="ion-no-padding ion-no-margin">
                        <IonItem lines="none">
                          <IonText className="ion-no-padding ion-no-margin">
                            <p className="ion-no-padding ion-no-margin">
                              {t("total")} {t("payment")} :{" "}
                              {Rupiah(
                                bill.billdet.reduce(
                                  (a: any, b: any) =>
                                    +a +
                                    +Number(
                                      b.billdet_collect
                                        ?.billdet_collect_amount ?? 0
                                    ),
                                  0
                                )
                              )}
                            </p>
                          </IonText>
                          <IonText
                            slot="end"
                            className="ion-no-padding ion-text-end"
                          >
                            <p className="ion-no-padding ion-no-margin">
                              {t("total")} {t("bill")} :{" "}
                              {Rupiah(
                                bill.billdet.reduce(
                                  (a: any, b: any) =>
                                    +a +
                                      +Number(b.billdet_si_total_bill ?? 0) ||
                                    0,
                                  0
                                )
                              )}
                            </p>
                          </IonText>
                        </IonItem>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol size="12" className="ion-no-padding ion-no-margin">
                    <IonText
                      color={"dark"}
                      className="ion-no-padding ion-no-margin"
                    >
                      <strong> &nbsp;{`${t("list")} ${t("bill")}`}</strong>
                    </IonText>
                  </IonCol>
                  {bill.billdet.map((value: any, key: any) => (
                    <IonCol
                      size="12"
                      key={key}
                      onClick={() => {
                        setBillDet(value);
                        presentCollect({
                          initialBreakpoint: 0.9,
                          breakpoints: [0, 1.0],
                          handleBehavior: "none",
                        });
                      }}
                    >
                      <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
                        <IonItem>
                          <IonText className="ion-no-padding ion-no-margin">
                            <p className="ion-no-padding ion-no-margin">
                              <IonIcon
                                size="small"
                                className="ion-no-padding ion-no-margin"
                                src={receiptOutline}
                              />
                              {value.billdet_si_id}
                            </p>
                            <p className="ion-no-padding ion-no-margin">
                              Jatuh Tempo:{" "}
                              {getNameDate(value.billdet_si_due_date)}
                            </p>
                          </IonText>
                          <IonText
                            className="ion-no-padding ion-no-margin ion-text-end"
                            slot="end"
                          >
                            <p className="ion-no-padding ion-no-margin">
                              {t("payment")} : &nbsp;
                              {Rupiah(value.billdet_srec_amount)}
                            </p>
                            <p className="ion-no-padding ion-no-margin">
                              <IonIcon
                                size="small"
                                style={{
                                  marginRight: "2px",
                                }}
                                src={calendarNumberOutline}
                              />
                              <strong>
                                &nbsp;
                                {Number(value.billdet_si_overdue_age)}
                                &nbsp;
                                {t("day")}
                              </strong>
                            </p>
                          </IonText>
                        </IonItem>
                        <IonCardContent className="ion-no-padding ion-no-margin">
                          <IonItem>
                            <IonText className="ion-no-padding ion-no-margin">
                              <p className="ion-no-padding ion-no-margin">
                                {t("total")} {t("invoice")} :{" "}
                                {Rupiah(value.billdet_si_total_bill ?? 0)}
                              </p>
                              <p className="ion-no-padding ion-no-margin">
                                {t("total")} {t("payment")} : &nbsp;
                                {Rupiah(value.billdet_si_total_payment ?? 0)}
                              </p>
                            </IonText>
                            <IonText
                              slot="end"
                              className="ion-no-padding ion-text-end"
                            >
                              <p className="ion-no-padding ion-no-margin">
                                {t("total")} {t("owing")} :{" "}
                                {Rupiah(value.billdet_si_total_owing ?? 0)}
                              </p>
                              <p className="ion-no-padding ion-no-margin">
                                {t("total")} {t("allocate")} :{" "}
                                {Rupiah(value.billdet_si_total_allocate ?? 0)}
                              </p>
                            </IonText>
                          </IonItem>
                          <IonItem className="ion-no-padding ion-no-margin">
                            <IonText
                              color={"dark"}
                              className="ion-no-padding ion-no-margin"
                            >
                              <span className="ion-no-padding ion-no-margin">
                                <strong>
                                  {" "}
                                  &nbsp;{`${t("list")} ${t("collect")}`}
                                </strong>
                              </span>
                            </IonText>
                          </IonItem>
                          <IonItem>
                            <IonText className="ion-no-padding ion-no-margin">
                              <h4>
                                <strong>
                                  Check ? &nbsp;
                                  <IonIcon
                                    color={
                                      value.billdet_collect
                                        ?.billdet_collect_is_check
                                        ? "success"
                                        : "danger"
                                    }
                                    src={
                                      value.billdet_collect
                                        ?.billdet_collect_is_check
                                        ? checkmarkCircleOutline
                                        : closeCircleOutline
                                    }
                                  />
                                </strong>
                              </h4>
                              <p>
                                <strong>{t("description")}</strong>
                                <br />
                                {value.billdet_collect?.billdet_collect_remark}
                              </p>
                            </IonText>
                            <IonText
                              className="ion-no-padding ion-no-margin ion-text-right"
                              slot="end"
                            >
                              <h4>
                                <strong>
                                  {t('image')} ? &nbsp;
                                  <IonIcon
                                    color={
                                      value.billdet_collect
                                        ?.billdet_collect_image
                                        ? "success"
                                        : "danger"
                                    }
                                    src={
                                      value.billdet_collect
                                        ?.billdet_collect_image
                                        ? checkmarkCircleOutline
                                        : closeCircleOutline
                                    }
                                  />
                                </strong>
                                <br />
                                <strong>
                                  {t("bill")} {t("payment")}{" "}
                                </strong>
                                <br />
                                {Rupiah(
                                  Number(
                                    value.billdet_collect
                                      ?.billdet_collect_amount ?? 0
                                  )
                                )}
                              </h4>
                            </IonText>
                          </IonItem>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonItem>
                  <IonText className="ion-no-padding ion-no-margin">
                    <h4>
                      <strong>
                        Check By &nbsp;
                        <IonIcon
                          color={bill.bill_check_by ? "success" : "danger"}
                          src={
                            bill.bill_check_by
                              ? checkmarkCircleOutline
                              : closeCircleOutline
                          }
                        />
                        {bill.bill_check_by ? bill.bill_check_by : "-"}
                      </strong>
                    </h4>
                  </IonText>

                  <IonText slot="end" className="ion-text-right">
                    <span>{bill.bill_check_by}</span>
                    <span>
                      {bill.bill_check_at
                        ? getNameDate(bill.bill_check_at, true)
                        : "-"}
                    </span>
                  </IonText>
                </IonItem>
              </IonCardContent>
            </IonCard>

            <SelectOption
              title={`${t("address")} ${t("module.customer")}`}
              items={customer?.customer_address.map((value: any) => ({
                value: value.customer_address_id,
                text: `${value.customer_address_name}, ${value.customer_address}`,
              }))}
              selectedItems={[]}
              onSelectionCancel={() =>
                setShowModalSelectOptionAddress(!showModalSelectOptionAddress)
              }
              onSelectionChange={addressSelectionChanged}
              multiple={false}
              isOpen={showModalSelectOptionAddress}
            />
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton
              data-desc="Lokasi"
              onClick={() => {
                setShowModalSelectOptionAddress(true);
              }}
            >
              <IonIcon icon={locateOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc={`History ${t("module.visit")}`}
              onClick={() => {
                presentHistoryVisit({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={mapOutline} />
            </IonFabButton>

            <IonFabButton
              data-desc={`History ${t("module.bill")}`}
              onClick={() => {
                presentHistoryBill({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={bookOutline} />
            </IonFabButton>

            <IonFabButton
              data-desc="Daftar Piutang"
              onClick={() => {
                presentAr({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={documentOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Back Order"
              onClick={() => {
                presentBackOrder({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={cartOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Pembayaran"
              onClick={() => {
                presentPayment({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={cashOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc={`${t("module.so")}`}
              onClick={() => {
                presentSales({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={walletOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

const ModalCollect: React.FC<any> = (props) => {
  const { deletePhoto, photos, takePhoto } = usePhotoGallery();
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [image, setImage] = useState<any>(
    props.billDet.billdet_collect?.billdet_collect_image
  );
  const [remark, setRemark] = useState<string>(
    props.billDet.billdet_collect?.billdet_collect_remark??""
  );
  const [baseUrl, setBaseUrl] = useState<any>("");
  const [amount, setAmount] = useState<any>(
    props.billDet.billdet_collect?.billdet_collect_amount ?? ""
  );
  const user = useSelector((state: any) => state.user.value);

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [
        {
          text: "Ok",
          cssClass: "alert-button-confirm",
          handler: (d: any) => {},
        },
      ],
    });
  };

  const selectImageSource = async () => {
    if (
      photos.length >= 1 ||
      image
    ) {
      alert({
        message: "Yakin ingin ubah foto ini? ",
        backdropDismiss: false,
        buttons: [
          { text: "Tidak", cssClass: "alert-button-cancel", role: "cancel" },
          { text: "Ya", cssClass: "alert-button-confirm", role: "confirm" },
        ],
        onDidDismiss: async (e) => {
          if (e.detail.role === "confirm") {
            setImage(null)
            if (photos.length) {
              for (let index = 0; index < photos.length; index++) {
                deletePhoto(photos[index]);
              }
            }
            await takePhoto(CameraSource.Camera);
          }
        },
      });
      return;
    }else{
      if (photos.length) {
        for (let index = 0; index < photos.length; index++) {
          deletePhoto(photos[index]);
        }
      }
      await takePhoto(CameraSource.Camera);
    }
  };

  const postCollect = async () => {
    if (remark.length < 10) {
      massageAlert("error", "Catatan Minimal 10 Karakter");
      return;
    }
    await presentLoading({ message: "Harap Tunggu" });
    const FormData = global.FormData;
    const formData = new FormData();
    if (photos.length) {
      formData.append("image", photos[0].blobData);
    }
    formData.append("si_id", props.billDet.billdet_si_id);
    formData.append("amount", amount);
    formData.append("remark", remark);
    await RestApi(
      `bill/collect/${props.billDet.billdet_bill_id}`,
      "post",
      formData,
      `application/x-www-form-urlencoded`
    )
      .then(async (res: any) => {
        if (res.data.code === 200) {
          if (photos.length) {
            for (let index = 0; index < photos.length; index++) {
              deletePhoto(photos[index]);
            }
          }
          props.onDismissDetail(true);
          massageAlert("success", res.data.message);
          dismissLoading();
        } else {
          massageAlert("error", res.data.message);
          dismissLoading();
        }
      })
      .catch((error: any) => {
        massageAlert("error", error.message);
        dismissLoading();
      });
  };

  useConstructor(async () => {
    const url = await Preferences.get({ key: "url" });
    setBaseUrl(url.value);
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        if (photos.length) {
          for (let index = 0; index < photos.length; index++) {
            deletePhoto(photos[index]);
          }
        }
      });
    }
    if (photos.length) {
      for (let index = 0; index < photos.length; index++) {
        deletePhoto(photos[index]);
      }
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class="toolbar-detail-visit ion-no-padding">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => {
              if (photos.length) {
                for (let index = 0; index < photos.length; index++) {
                  deletePhoto(photos[index]);
                }
              }
              props.onDismissDetail(false);
            }}
          >
            <IonIcon color="light" slot="start" src={closeOutline} />
          </IonButton>
          <IonItem className="ion-no-padding">
            <IonLabel className="ion-no-padding" color="light">
              <h4>
                <strong>
                  Catatan & Upload Gambar ({props.billDet.billdet_si_id})
                </strong>
              </h4>
            </IonLabel>
            <IonIcon
              slot="end"
              size="medium"
              color="primary"
              icon={chatboxEllipsesOutline}
            />
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonItem>
            <IonInput
              labelPlacement="floating"
              placeholder={`Jumlah ${t("payment")}`}
              label={`Jumlah ${t("payment")}`}
              id="billdet_collect_amount"
              name="billdet_collect_amount"
              type={"number"}
              onIonInput={(e: any) => setAmount(Number(e.detail.value))}
              value={amount}
            />
          </IonItem>
          <IonItem>
            <IonTextarea
              placeholder="Isi Catatan"
              labelPlacement="floating"
              label="Catatan"
              name="remark"
              onIonInput={(e: any) => setRemark(e.detail.value!)}
              value={remark}
              minlength={10}
            ></IonTextarea>
          </IonItem>
          <IonCard>
            <IonText>Gambar (Optional)</IonText>
            <IonItem onClick={() => selectImageSource()}>
              <IonText slot="start">Upload Gambar</IonText>
              <IonIcon slot="end" src={cameraOutline}></IonIcon>
            </IonItem>
          </IonCard>
          <IonButton expand="block" onClick={postCollect}>
            Kirim
          </IonButton>
          <IonRow>
            {photos.length ? (
              <IonCol size="12">
                <IonCard>
                  <IonImg src={photos[0]?.webviewPath} />
                </IonCard>
              </IonCol>
            ) : (
              ""
            )}
            {image ? (
              <IonCol size="12">
                <IonCard>
                  <IonImg
                    src={
                      baseUrl + "/image/bill/" + user.company_id + "/" + image
                    }
                  />
                </IonCard>
              </IonCol>
            ) : (
              ""
            )}
          </IonRow>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Detail;

