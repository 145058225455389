import {
  IonPage,
  IonContent,
  IonSkeletonText,
  IonIcon,
  IonLabel,
  IonCard,
  IonItem,
  IonList,
  IonListHeader,
  IonThumbnail,
  IonImg,
  useIonAlert,
  IonText,
  IonHeader,
  IonToolbar,
  IonButton,
  IonBadge,
  IonRow,
  IonCol,
  useIonToast,
  IonCardContent,
} from '@ionic/react';
import React, { useState} from 'react';
import { getNameDate, Rupiah } from '../../services/Library';
import { arrowBackOutline, calendarNumberOutline, cashOutline, checkmarkCircleOutline, chevronBackCircleOutline, chevronForwardCircleOutline, closeCircleOutline, personCircleOutline, receiptOutline, sadOutline } from 'ionicons/icons';
import { RestApi } from '../../services/Api';
import { useSelector } from 'react-redux';
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';
import "./Calendar.scss";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Calendar: React.FC<any> = (props) =>{
    const user = useSelector((state: any) => state.user.value);
    const today = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const [alert] = useIonAlert();
    const { t } = useTranslation(["general"]);
    const [listCalendar, setListCalendar] = useState<any>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [searchDate, setSearchDate] = useState<any>(
      today.toISOString().slice(0, 10)
    );
    const [presentToast] = useIonToast();

    const massageAlert = async (status:string,body:string) => {
      alert({
          header: t(status),
          message: body,
          cssClass: `header-alert-${status}`,
          buttons: [{ text: 'Ok', handler: (d:any) => {} }]
      })
    }    

    const currentDate = (param: number) => {
      let currentDate = new Date(searchDate);
      setListCalendar(null);
      setLoaded(false);
      switch (param) {
        case 1:
          let nextDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 2,
            0
          );
          setSearchDate(nextDate.toISOString().slice(0, 10));
          getCalendarList(`${nextDate.toISOString().slice(0, 10)}`);
          break;
        case 2:
          let backDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            -1
          );
          setSearchDate(backDate.toISOString().slice(0, 10));
          getCalendarList(`${backDate.toISOString().slice(0, 10)}`);
          break;
        default:
          break;
      }
    };

    const statusPayment: any = [
      {
        name: t("no"),
        color: "danger",
      },
      {
        name: t("yes"),
        color: "success",
      },
    ];

    const getCalendarList = async (date: string) => {
      await RestApi(
        `bill/calendar-bill/${date}`,
        "get",
        {},
        "application/json"
      )
        .then((res: any) => {
          if (res.data.code === 200) {
            setListCalendar(res.data.data);
          } else {
            setListCalendar(null);
            presentToast({
              message: res.data.message,
              duration: 1500,
              color: "danger",
              icon: sadOutline,
            });
          }
          setLoaded(true);
        })
        .catch((err: any) => {
          props.onDismissCalendar();
          massageAlert('error', err.message);
        });
    };

    useConstructor(() => {
      setSearchDate(today.toISOString().slice(0, 10))
      getCalendarList(`${today.toISOString().slice(0, 10)}`);
    });

    return (
      <IonPage>
        <IonHeader className="toolbar-history">
          <IonToolbar className="ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismissCalendar()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonLabel color="light" className="ion-text-center ion-no-padding">
              <h1>List Calendar</h1>
            </IonLabel>
            <IonButton color={"light"} slot="end" fill="clear"></IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent className="content-history">
          <IonCard className="ion-no-padding">
            <IonRow className="ion-no-padding">
              <IonCol size="2" className="ion-no-padding">
                <br />
                <IonText color="dark" className="ion-no-padding">
                  <IonIcon
                    size="large"
                    className="ion-padding ion-float-left"
                    icon={chevronBackCircleOutline}
                    onClick={() => {
                      currentDate(2);
                    }}
                  />
                </IonText>
              </IonCol>
              <IonCol size="8" className="ion-no-padding">
                <IonLabel
                  color="dark"
                  className="ion-text-center ion-no-padding"
                >
                  <p>{getNameDate()}</p>
                  <h2>
                    <strong>{searchDate.substring(0, 7)}</strong>
                  </h2>
                  <p>
                    {user.user_name} ({user.user_salesman_id})
                  </p>
                </IonLabel>
              </IonCol>
              <IonCol size="2" className="ion-no-padding">
                <IonText color="dark" className="ion-no-padding">
                  <br />
                  <IonIcon
                    size="large"
                    className="ion-padding ion-float-right"
                    icon={chevronForwardCircleOutline}
                    onClick={() => {
                      currentDate(1);
                    }}
                  />
                </IonText>
              </IonCol>
            </IonRow>
          </IonCard>

          {listCalendar ? (
            <IonList className="main-card">
              {listCalendar.map((bill: any, key: any) => (
                <IonCol size="12" key={key}>
                  <IonCard>
                    <IonCardContent className="ion-no-padding ion-no-margin">
                      <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol size="12">
                          <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
                            <IonItem>
                              <IonText className="ion-no-padding ion-no-margin">
                                <p className="ion-no-padding ion-no-margin">
                                  <IonIcon
                                    size="small"
                                    className="ion-no-padding ion-no-margin"
                                    src={cashOutline}
                                  />
                                  {t("payment")} ?{" "}
                                  <IonBadge
                                    slot="end"
                                    color={
                                      statusPayment[bill.bill_has_receipt].color
                                    }
                                  >
                                    {statusPayment[bill.bill_has_receipt].name}
                                  </IonBadge>
                                </p>
                                <p className="ion-no-padding ion-no-margin">
                                  <IonIcon
                                    size="small"
                                    className="ion-no-padding ion-no-margin"
                                    src={personCircleOutline}
                                  />
                                  {bill.bill_collector_name}
                                </p>
                              </IonText>
                              <IonText
                                className="ion-no-padding ion-no-margin ion-text-end"
                                slot="end"
                              >
                                <p className="ion-no-padding ion-no-margin">
                                  <strong>{bill.bill_id}</strong>
                                </p>
                                <p className="ion-no-padding ion-no-margin">
                                  <IonIcon
                                    size="small"
                                    style={{
                                      marginRight: "2px",
                                    }}
                                    src={calendarNumberOutline}
                                  />
                                  <strong>{getNameDate(bill.bill_date)}</strong>
                                </p>
                              </IonText>
                            </IonItem>
                            <IonCardContent className="ion-no-padding ion-no-margin">
                              <IonItem lines="none">
                                <IonText className="ion-no-padding ion-no-margin">
                                  <p className="ion-no-padding ion-no-margin">
                                    {t("total")} {t("payment")} :{" "}
                                    {Rupiah(
                                      bill.billdet.reduce(
                                        (a: any, b: any) =>
                                          +a +
                                          +Number(
                                            b.billdet_collect
                                              ?.billdet_collect_amount ?? 0
                                          ),
                                        0
                                      )
                                    )}
                                  </p>
                                </IonText>
                                <IonText
                                  slot="end"
                                  className="ion-no-padding ion-text-end"
                                >
                                  <p className="ion-no-padding ion-no-margin">
                                    {t("total")} {t("bill")} :{" "}
                                    {Rupiah(
                                      bill.billdet.reduce(
                                        (a: any, b: any) =>
                                          +a +
                                            +Number(
                                              b.billdet_si_total_bill ?? 0
                                            ) || 0,
                                        0
                                      )
                                    )}
                                  </p>
                                </IonText>
                              </IonItem>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonText
                            color={"dark"}
                            className="ion-no-padding ion-no-margin"
                          >
                            <strong>
                              {" "}
                              &nbsp;{`${t("list")} ${t("bill")}`}
                            </strong>
                          </IonText>
                        </IonCol>
                        {bill.billdet.map((value: any, key: any) => (
                          <IonCol size="12" key={key}>
                            <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
                              <IonItem>
                                <IonText className="ion-no-padding ion-no-margin">
                                  <p className="ion-no-padding ion-no-margin">
                                    <IonIcon
                                      size="small"
                                      className="ion-no-padding ion-no-margin"
                                      src={receiptOutline}
                                    />
                                    {value.billdet_si_id}
                                  </p>
                                  <p className="ion-no-padding ion-no-margin">
                                    Jatuh Tempo:{" "}
                                    {getNameDate(value.billdet_si_due_date)}
                                  </p>
                                </IonText>
                                <IonText
                                  className="ion-no-padding ion-no-margin ion-text-end"
                                  slot="end"
                                >
                                  <p className="ion-no-padding ion-no-margin">
                                    {t("payment")} : &nbsp;
                                    {Rupiah(value.billdet_srec_amount)}
                                  </p>
                                  <p className="ion-no-padding ion-no-margin">
                                    <IonIcon
                                      size="small"
                                      style={{
                                        marginRight: "2px",
                                      }}
                                      src={calendarNumberOutline}
                                    />
                                    <strong>
                                      &nbsp;
                                      {Number(value.billdet_si_overdue_age)}
                                      &nbsp;
                                      {t("day")}
                                    </strong>
                                  </p>
                                </IonText>
                              </IonItem>
                              <IonCardContent className="ion-no-padding ion-no-margin">
                                <IonItem>
                                  <IonText className="ion-no-padding ion-no-margin">
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("invoice")} :{" "}
                                      {Rupiah(value.billdet_si_total_bill ?? 0)}
                                    </p>
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("payment")} : &nbsp;
                                      {Rupiah(
                                        value.billdet_si_total_payment ?? 0
                                      )}
                                    </p>
                                  </IonText>
                                  <IonText
                                    slot="end"
                                    className="ion-no-padding ion-text-end"
                                  >
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("owing")} :{" "}
                                      {Rupiah(
                                        value.billdet_si_total_owing ?? 0
                                      )}
                                    </p>
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("allocate")} :{" "}
                                      {Rupiah(
                                        value.billdet_si_total_allocate ?? 0
                                      )}
                                    </p>
                                  </IonText>
                                </IonItem>
                                <IonItem className="ion-no-padding ion-no-margin">
                                  <IonText
                                    color={"dark"}
                                    className="ion-no-padding ion-no-margin"
                                  >
                                    <span className="ion-no-padding ion-no-margin">
                                      <strong>
                                        {" "}
                                        &nbsp;{`${t("list")} ${t("collect")}`}
                                      </strong>
                                      &nbsp;
                                      <small>
                                        ({value.si_customer_id} -{" "}
                                        {value.si_customer_name})
                                      </small>
                                    </span>
                                  </IonText>
                                </IonItem>
                                <IonItem>
                                  <IonText className="ion-no-padding ion-no-margin">
                                    <h4>
                                      <strong>
                                        Check ? &nbsp;
                                        <IonIcon
                                          color={
                                            value.billdet_collect
                                              ?.billdet_collect_is_check
                                              ? "success"
                                              : "danger"
                                          }
                                          src={
                                            value.billdet_collect
                                              ?.billdet_collect_is_check
                                              ? checkmarkCircleOutline
                                              : closeCircleOutline
                                          }
                                        />
                                      </strong>
                                    </h4>
                                    <p>
                                      <strong>{t("description")}</strong>
                                      <br />
                                      {
                                        value.billdet_collect
                                          ?.billdet_collect_remark
                                      }
                                    </p>
                                  </IonText>
                                  <IonText
                                    className="ion-no-padding ion-no-margin ion-text-right"
                                    slot="end"
                                  >
                                    <h4>
                                      <strong>
                                        {t("image")} ? &nbsp;
                                        <IonIcon
                                          color={
                                            value.billdet_collect
                                              ?.billdet_collect_image
                                              ? "success"
                                              : "danger"
                                          }
                                          src={
                                            value.billdet_collect
                                              ?.billdet_collect_image
                                              ? checkmarkCircleOutline
                                              : closeCircleOutline
                                          }
                                        />
                                      </strong>
                                      <br />
                                      <strong>
                                        {t("bill")} {t("payment")}{" "}
                                      </strong>
                                      <br />
                                      {Rupiah(
                                        Number(
                                          value.billdet_collect
                                            ?.billdet_collect_amount ?? 0
                                        )
                                      )}
                                    </h4>
                                  </IonText>
                                </IonItem>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        ))}
                      </IonRow>
                      <IonItem>
                        <IonText className="ion-no-padding ion-no-margin">
                          <h4>
                            <strong>
                              Check By &nbsp;
                              <IonIcon
                                color={
                                  bill.bill_check_by ? "success" : "danger"
                                }
                                src={
                                  bill.bill_check_by
                                    ? checkmarkCircleOutline
                                    : closeCircleOutline
                                }
                              />
                              {bill.bill_check_by ? bill.bill_check_by : "-"}
                            </strong>
                          </h4>
                        </IonText>

                        <IonText slot="end" className="ion-text-right">
                          <span>{bill.bill_check_by}</span>
                          <span>
                            {bill.bill_check_at
                              ? getNameDate(bill.bill_check_at, true)
                              : "-"}
                          </span>
                        </IonText>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
            </IonList>
          ) : (
            <>
              {loaded ? (
                <IonImg
                  className="img-empty"
                  src={"assets/img/search-empty.webp"}
                />
              ) : (
                <IonList>
                  <IonListHeader>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80px" }}
                    ></IonSkeletonText>
                  </IonListHeader>
                  {loadList.map((value: any, key: any) => (
                    <IonItem key={key}>
                      <IonThumbnail slot="start">
                        <IonSkeletonText animated={true}></IonSkeletonText>
                      </IonThumbnail>
                      <IonLabel>
                        <h3>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "80%" }}
                          ></IonSkeletonText>
                        </h3>
                        <p>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "60%" }}
                          ></IonSkeletonText>
                        </p>
                        <p>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "30%" }}
                          ></IonSkeletonText>
                        </p>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    );
};

export default Calendar;
