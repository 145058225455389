import {
    IonPage,
    IonContent,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonBadge,
    IonRow,
    IonGrid,
    IonCol,
} from '@ionic/react';
import React, { useState} from 'react';
import { getNameDate, Rupiah } from '../../services/Library';
import { arrowBackOutline,  calendarNumberOutline,  cashOutline,  checkmarkCircleOutline,  closeCircleOutline, personCircleOutline, receiptOutline } from 'ionicons/icons';
import { RestApi } from '../../services/Api';
import { Customer } from '../../models/Modal'
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';
import './History.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const History: React.FC<Customer> = (props) =>{
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [listHistory, setListHistory] = useState<any>([]);
  const [customer, setCustomer] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const massageAlert = async (status:string,body:string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ text: 'Ok', handler: (d:any) => {} }]
    })
  }

  const getHistoryList = async (page:string,next:boolean) => {
      await RestApi(`${page}`,'get',{},'application/json')
      .then((res:any) => {
          if (res.data.code === 200) {
              setListHistory(res.data.data.bill)
              setCustomer(res.data.data.customer);
              setLoaded(true)
          }else{
              props.onDismissDetail()
              massageAlert('error',res.data.message)
          }
      })
      .catch((err:any) => {
          props.onDismissDetail()
          massageAlert('error',err.message)
      });
  }

  const statusPayment: any = [
    {
      name: t("no"),
      color: "danger",
    },
    {
      name: t("yes"),
      color: "success",
    },
  ];
  
  useConstructor(() => {
    getHistoryList(`bill/history-customer/${props.idCustomer}`,false)
  });

  return (
    <IonPage>
      <IonHeader className="toolbar-history">
        {loaded ? (
          <IonToolbar className="ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismissDetail()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonLabel color="light" className="ion-text-center ion-no-padding">
              <h1>{t('list')} {t('bill')}</h1>
              <h4>
                <strong>
                  {customer.customer_name} ({customer.customer_id})
                </strong>
              </h4>
            </IonLabel>
            <IonLabel slot="end" className="ion-padding">
              <IonBadge color="light" slot="end">
                {listHistory.length}
              </IonBadge>
            </IonLabel>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="content-history">
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        {listHistory.length && loaded ? (
          <IonGrid>
            <IonRow>
              {listHistory.map((bill: any, key: any) => (
                <IonCol
                  size="12"
                  key={key}
                  
                >
                  <IonCard>
                    <IonCardContent className="ion-no-padding ion-no-margin">
                      <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol size="12">
                          <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
                            <IonItem>
                              <IonText className="ion-no-padding ion-no-margin">
                                <p className="ion-no-padding ion-no-margin">
                                  <IonIcon
                                    size="small"
                                    className="ion-no-padding ion-no-margin"
                                    src={cashOutline}
                                  />
                                  {t("payment")} ?{" "}
                                  <IonBadge
                                    slot="end"
                                    color={statusPayment[bill.bill_has_receipt].color}
                                  >
                                    {statusPayment[bill.bill_has_receipt].name}
                                  </IonBadge>
                                </p>
                                <p className="ion-no-padding ion-no-margin">
                                  <IonIcon
                                    size="small"
                                    className="ion-no-padding ion-no-margin"
                                    src={personCircleOutline}
                                  />
                                  {bill.bill_collector_name}
                                </p>
                              </IonText>
                              <IonText
                                className="ion-no-padding ion-no-margin ion-text-end"
                                slot="end"
                              >
                                <p className="ion-no-padding ion-no-margin">
                                  <strong>{bill.bill_id}</strong>
                                </p>
                                <p className="ion-no-padding ion-no-margin">
                                  <IonIcon
                                    size="small"
                                    style={{
                                      marginRight: "2px",
                                    }}
                                    src={calendarNumberOutline}
                                  />
                                  <strong>{getNameDate(bill.bill_date)}</strong>
                                </p>
                              </IonText>
                            </IonItem>
                            <IonCardContent className="ion-no-padding ion-no-margin">
                              <IonItem lines="none">
                                <IonText className="ion-no-padding ion-no-margin">
                                  <p className="ion-no-padding ion-no-margin">
                                    {t("total")} {t("payment")} :{" "}
                                    {Rupiah(
                                      bill.billdet.reduce(
                                        (a: any, b: any) =>
                                          +a +
                                          +Number(
                                            b.billdet_collect
                                              ?.billdet_collect_amount ?? 0
                                          ),
                                        0
                                      )
                                    )}
                                  </p>
                                </IonText>
                                <IonText
                                  slot="end"
                                  className="ion-no-padding ion-text-end"
                                >
                                  <p className="ion-no-padding ion-no-margin">
                                    {t("total")} {t("bill")} :{" "}
                                    {Rupiah(
                                      bill.billdet.reduce(
                                        (a: any, b: any) =>
                                          +a +
                                            +Number(b.billdet_si_total_bill ?? 0) ||
                                          0,
                                        0
                                      )
                                    )}
                                  </p>
                                </IonText>
                              </IonItem>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                        <IonCol size="12" className="ion-no-padding ion-no-margin">
                          <IonText
                            color={"dark"}
                            className="ion-no-padding ion-no-margin"
                          >
                            <strong> &nbsp;{`${t("list")} ${t("bill")}`}</strong>
                          </IonText>
                        </IonCol>
                        {bill.billdet.map((value: any, key: any) => (
                          <IonCol
                            size="12"
                            key={key}
                            
                          >
                            <IonCard className="ion-no-padding ion-no-margin ion-padding-top">
                              <IonItem>
                                <IonText className="ion-no-padding ion-no-margin">
                                  <p className="ion-no-padding ion-no-margin">
                                    <IonIcon
                                      size="small"
                                      className="ion-no-padding ion-no-margin"
                                      src={receiptOutline}
                                    />
                                    {value.billdet_si_id}
                                  </p>
                                  <p className="ion-no-padding ion-no-margin">
                                    Jatuh Tempo:{" "}
                                    {getNameDate(value.billdet_si_due_date)}
                                  </p>
                                </IonText>
                                <IonText
                                  className="ion-no-padding ion-no-margin ion-text-end"
                                  slot="end"
                                >
                                  <p className="ion-no-padding ion-no-margin">
                                    {t("payment")} : &nbsp;
                                    {Rupiah(value.billdet_srec_amount)}
                                  </p>
                                  <p className="ion-no-padding ion-no-margin">
                                    <IonIcon
                                      size="small"
                                      style={{
                                        marginRight: "2px",
                                      }}
                                      src={calendarNumberOutline}
                                    />
                                    <strong>
                                      &nbsp;
                                      {Number(value.billdet_si_overdue_age)}
                                      &nbsp;
                                      {t("day")}
                                    </strong>
                                  </p>
                                </IonText>
                              </IonItem>
                              <IonCardContent className="ion-no-padding ion-no-margin">
                                <IonItem>
                                  <IonText className="ion-no-padding ion-no-margin">
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("invoice")} :{" "}
                                      {Rupiah(value.billdet_si_total_bill ?? 0)}
                                    </p>
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("payment")} : &nbsp;
                                      {Rupiah(value.billdet_si_total_payment ?? 0)}
                                    </p>
                                  </IonText>
                                  <IonText
                                    slot="end"
                                    className="ion-no-padding ion-text-end"
                                  >
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("owing")} :{" "}
                                      {Rupiah(value.billdet_si_total_owing ?? 0)}
                                    </p>
                                    <p className="ion-no-padding ion-no-margin">
                                      {t("total")} {t("allocate")} :{" "}
                                      {Rupiah(value.billdet_si_total_allocate ?? 0)}
                                    </p>
                                  </IonText>
                                </IonItem>
                                <IonItem className="ion-no-padding ion-no-margin">
                                  <IonText
                                    color={"dark"}
                                    className="ion-no-padding ion-no-margin"
                                  >
                                    <span className="ion-no-padding ion-no-margin">
                                      <strong>
                                        {" "}
                                        &nbsp;{`${t("list")} ${t("collect")}`}
                                      </strong>
                                    </span>
                                  </IonText>
                                </IonItem>
                                <IonItem>
                                  <IonText className="ion-no-padding ion-no-margin">
                                    <h4>
                                      <strong>
                                        Check ? &nbsp;
                                        <IonIcon
                                          color={
                                            value.billdet_collect
                                              ?.billdet_collect_is_check
                                              ? "success"
                                              : "danger"
                                          }
                                          src={
                                            value.billdet_collect
                                              ?.billdet_collect_is_check
                                              ? checkmarkCircleOutline
                                              : closeCircleOutline
                                          }
                                        />
                                      </strong>
                                    </h4>
                                    <p>
                                      <strong>{t("description")}</strong>
                                      <br />
                                      {value.billdet_collect?.billdet_collect_remark}
                                    </p>
                                  </IonText>
                                  <IonText
                                    className="ion-no-padding ion-no-margin ion-text-right"
                                    slot="end"
                                  >
                                    <h4>
                                      <strong>
                                        {t("image")} ? &nbsp;
                                        <IonIcon
                                          color={
                                            value.billdet_collect
                                              ?.billdet_collect_image
                                              ? "success"
                                              : "danger"
                                          }
                                          src={
                                            value.billdet_collect
                                              ?.billdet_collect_image
                                              ? checkmarkCircleOutline
                                              : closeCircleOutline
                                          }
                                        />
                                      </strong>
                                      <br />
                                      <strong>
                                        {t("bill")} {t("payment")}{" "}
                                      </strong>
                                      <br />
                                      {Rupiah(
                                        Number(
                                          value.billdet_collect
                                            ?.billdet_collect_amount ?? 0
                                        )
                                      )}
                                    </h4>
                                  </IonText>
                                </IonItem>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        ))}
                      </IonRow>
                      <IonItem>
                        <IonText className="ion-no-padding ion-no-margin">
                          <h4>
                            <strong>
                              Check By &nbsp;
                              <IonIcon
                                color={bill.bill_check_by ? "success" : "danger"}
                                src={
                                  bill.bill_check_by
                                    ? checkmarkCircleOutline
                                    : closeCircleOutline
                                }
                              />
                              {bill.bill_check_by ? bill.bill_check_by : "-"}
                            </strong>
                          </h4>
                        </IonText>

                        <IonText slot="end" className="ion-text-right">
                          <span>{bill.bill_check_by}</span>
                          <span>
                            {bill.bill_check_at
                              ? getNameDate(bill.bill_check_at, true)
                              : "-"}
                          </span>
                        </IonText>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default History;
