import {
    IonContent,
    IonPage,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonBadge,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonHeader,
    IonToolbar,
    IonButton,
    useIonToast,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
    useIonModal,
} from '@ionic/react';
import React, { useState } from 'react';
import { storefrontOutline, arrowBackOutline, warningOutline, chevronDownCircleOutline, calendar } from 'ionicons/icons';
import { RestApi } from './../services/Api';
import { loadList } from '../models/Data';
import { useTranslation } from 'react-i18next';
import { getNameDate, Rupiah } from '../services/Library';
import Detail from '../components/bill/Detail';
import Calendar from '../components/bill/Calendar';
import './Bill.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Bill: React.FC = () => {
  const [presentToast] = useIonToast();
  const { t } = useTranslation(["general"]);
  const [getDetailBill, setGetDetailBill] = useState<any>([]);
  const [alert] = useIonAlert();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [listBill, setListBill] = useState<any>([]);
  const massageAlert = async (status:string,body:string) => {
      alert({
          header: t(status),
          message: body,
          cssClass: `header-alert-${status}`,
          buttons: [{
              cssClass: 'alert-button-confirm', 
              text: 'Ok'
          }],
      })
  }

  const getBillList = async (page:string,next:boolean) => {
      await RestApi(page,'get',{},'application/json')
      .then((res:any) => {
          if (res.data.code === 200) {
              setListBill(res.data.data);
          }else{
              setListBill([])
              presentToast({
                message: res.data.message,
                duration: 2500,
                color: "danger",
                icon: warningOutline,
              }); 
          }
          setLoaded(true)
      })
      .catch((err:any) => {
          massageAlert('error',err.message)
          setLoaded(true)
      });
  }

  const handleDismissDetail = () => {
    dismissDetail();
  };

  const [presentDetail, dismissDetail] = useIonModal(Detail, {
    detailBill: getDetailBill,
    onDismissDetail: handleDismissDetail,
    type: "bill",
  });

  const handleDismissCalendar = () => {
    dismissCalendar();
  };

  const [presentCalendar, dismissCalendar] = useIonModal(Calendar, {
    onDismissCalendar: handleDismissCalendar,
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getBillList(`bill`, false);
    event.detail.complete();
  };
  
  useConstructor(() => {
      getBillList(`bill`,false)
  });

  return (
    <IonPage>
      <IonHeader>
        {loaded ? (
          <IonToolbar>
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              routerLink="/home"
              routerDirection="back"
              className=""
              size="small"
            >
              <IonIcon color="light" src={arrowBackOutline} />
            </IonButton>
            <IonLabel color="light" className="ion-text-center ion-no-padding">
              <h1>
                <strong>{listBill[0]?.bill_id}</strong>
              </h1>
              <h4>{getNameDate(listBill[0]?.bill_date)}</h4>
            </IonLabel>
            <IonButton
              color="light"
              onClick={() => {
                presentCalendar({
                  cssClass: "modal-fullscreen",
                });
              }}
              slot="end"
              fill="clear"
            >
              <IonIcon color="light" src={calendar} />
            </IonButton>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="bill-content">
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t("loading") ?? ""}
            refreshingSpinner="circles"
            refreshingText={t("loading") ?? ""}
          ></IonRefresherContent>
        </IonRefresher>
        {listBill.length && loaded ? (
          <IonList>
            {listBill.map((value: any, key: any) => (
              <IonCard
                className="ion-padding"
                key={key}
                onClick={() => {
                  setGetDetailBill(value);
                  presentDetail({
                    cssClass: "modal-fullscreen",
                  });
                }}
              >
                <IonItem className="ion-no-padding">
                  <IonIcon
                    size="small"
                    className="ion-no-padding"
                    src={storefrontOutline}
                  />
                  <IonLabel className="ion-no-padding">
                    <h4>
                      &nbsp;
                      <strong>{value.customer_name}</strong>
                    </h4>
                  </IonLabel>
                  <IonBadge slot="end" color="dark">
                    {Rupiah(value.bill_total_amount)}
                  </IonBadge>
                </IonItem>
                <IonCardContent className="ion-no-padding">
                  <IonItem className="ion-no-padding" lines="none">
                    <IonLabel className="ion-no-padding">
                      <p>{`${value.customer_address_name}, ${value.customer_address}`}</p>
                    </IonLabel>
                    <IonLabel slot="end" className="ion-no-padding">
                      <p>{value.customer_phone}</p>
                    </IonLabel>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
          </IonList>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Bill;
