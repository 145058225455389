import { combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { UserReducer } from "./UserReducer";
import { CustomerReducer } from "./CustomerReducer";
import { VisitReducer } from "./VisitReducer";
import { CartReducer } from "./CartReducer";
import { logger } from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['user','customer','visit','cart'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: UserReducer,
    customer: CustomerReducer,
    visit: VisitReducer,
    cart: CartReducer
  })
);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>{
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    if (process.env.NODE_ENV === "development") {
      middlewares.push(logger as any);
    }
    return middlewares;
  },
});


const Persistor = persistStore(Store);

const state = Store.getState;

const ClearPresistor = () =>{
  Persistor.pause();
  Persistor.flush().then(() => {
    return Persistor.purge();
  });
}

export type State = ReturnType<typeof state>;
export { Store, Persistor, ClearPresistor };